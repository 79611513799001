import React from 'react';
import { Link } from 'gatsby'
import BlockRSO from './BlockRSO'
import NavBarBis from './NavBarBis'
import Toggle from './Toggle'
import styled from 'styled-components'
import COLORS from '../utils/colors'
import SIZES from '../utils/sizes'


const StyledHeader = styled.header`
 
  /*position:fixed !important;
  top:0;
  left:0;*/
  width:100%;
  padding:16px 48px;
  text-decoration: none;
 
  /*border-bottom:solid 3px #EEE;
  box-shadow: 0px 2px 8px ${COLORS.primaryHover};
  background:linear-gradient(${COLORS.primary} , ${COLORS.primaryHover});*/
  z-index: 10;

  .headerWrapper{

    max-width:${SIZES.container} ;
    margin:auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    position:relative;
    
  
    a.logoLink{

      display:flex;
      align-items:center;

      .logoSVG{
        width:128px;
        
      }
    }

    
  }
 
  &.absolute{
    position:absolute;

    
  }

  @media (max-width: ${SIZES.firstBreakPoint}){

    padding:16px 48px;

  } 

  @media (max-width: ${SIZES.thirdBreakPoint}){

    padding:8px 24px;
    .headerWrapper{
      a.logoLink{
        .logoSVG{
          width:96px;        
        }
      }
    }

  } 
`

const Header = ({ theme, page }) => {

    const nav = theme==="index" ? '' : <NavBarBis page={page} />
    const absolute = page ==="portfolio" ? "absolute" : ""
    const logoSRC = theme ==="index" || theme ==="template"? '../logo-b-com-2024-blanc.svg' : '../logo-b-com-2024-color.svg';


    return (
        <StyledHeader className={absolute}>
         
          <div className="headerWrapper">
         
              <Link className="logoLink" to="/"><img className="logoSVG" src={logoSRC} alt="logo b-com" /></Link>
             
              <BlockRSO mini="mini"/>
       
            {nav}
         
            <Toggle page={page}/>
       
          </div>
          
        </StyledHeader>
    );
};

export default Header;