import React from 'react'
import {graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import FooterNavBar from './FooterNavBar'
import COLORS from '../utils/colors'
import SIZES from '../utils/sizes'
import stroke from "../images/stroke-top.png"


const StyledFooter = styled.footer`

    background-color: ${COLORS.primary};
    padding: 64px 48px 24px;
    position:relative;

    .stroke{
        position:absolute;
        width:100%;
        height:136px;
        z-index:-1;
        left:0;
        background:url(${stroke}) center center no-repeat;
        background-size: cover;
       
        top:0px;
        transform: translateY(-50%);
    }

    .footer-content{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    *{
        color: white;
    }


    p{
        font-size: 0.8rem;
     
        text-align: right;
    }

    .logoSVGfooter{
        width:198px;
        margin-bottom:24px;
    }

    @media (max-width: ${SIZES.secondBreakPoint}){

      
        padding: 64px 24px 24px;

        .footer-content{
            flex-direction:column;
            align-items: center;
        }

        .footer-logo{
            margin-bottom:24px;
        }

        p{
            margin-top:24px;
        }
 
     }

     @media (max-width: ${SIZES.fourthBreakPoint}){
    
     .stroke{
        height:56px;
       }
    }

`

const Footer = () => {

    const getAuthor = graphql`

        query{
            site{
                siteMetadata{
                    title
                    author
                }
            }

              
        }
    `

    const data = useStaticQuery(getAuthor);
    return (
        <StyledFooter>
                <div className="stroke stroke-top"></div>
                <div className="footer-content">
                    <img class="logoSVGfooter" src={'../logo-b-com-2024-blanc.svg'} alt="logo b-com" />
                    {/*<Image className="footer-logo" fluid={data.logo.childImageSharp.fluid} imgStyle= { {objectFit:'contain'} }/>*/}
                    <FooterNavBar />
                    <p>Créé par {data.site.siteMetadata.author}, 2020</p>

                </div>
            </StyledFooter>
    );
};

export default Footer;