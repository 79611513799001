import React from 'react';

import styled from 'styled-components'
import COLORS from "../utils/colors"

const StyledRSO = styled.a`
    display:block;
    
    background-color:${COLORS.thirdary};
    width:50px;
    height:50px;
    border-radius:50%;
    position:relative;
    transition: background-color 0.3s ease-out;

    &.mini{
        background-color:${COLORS.secundary};
        width:30px;
        height:30px;
     

        *{
            color: ${COLORS.primary};
            font-size:1rem;
        }

        &:hover{
            background-color:  ${COLORS.primary};
        }
    }


    *{
        color: ${COLORS.secundary};
        position:absolute;
        top:50%;
        left:50%;
        font-size:1.4rem;
        transform:translateX(-50%) translateY(-50%);
        transition: color 0.3s ease-out;
        
    }

    &:hover{
        background-color:  ${COLORS.thirdaryHover};
        
    }

    &:hover *{
        color: ${COLORS.secundary};
    }
`

const RSOIcon = ({to, children, mini=""}) => {
    return (
        <StyledRSO className={mini} href={to}>{children}</StyledRSO>
    );
};

export default RSOIcon;