import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'

import BlockRSO from './BlockRSO'

import SIZES from '../utils/sizes'
import '../utils/font.scss'
import '../styles/index.scss'
import '../styles/global.scss'



const MainContainer = styled.div`    

    background-color: ${props => props.themeBG.backgroundColor} ;
    margin: 0 auto;
    max-width: 100%;
    overflow:hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position:relative !important;


    .fond-hero{
        background: radial-gradient(circle, rgba(255,153,107,1) 0%, rgba(255,109,44,1) 100%);
        width:100vw;
        height:960px;
        position:absolute;
        top:0;
        left:0;

        .image-fond{
           width:45%;
           height:400px;
           position:absolute !important;
           bottom:220px;
           left:5vw;
            
        }
         
    }

    @media (max-width: ${SIZES.firstBreakPoint}){

        .fond-hero{
            height:960px;

        }
    }
    

   

    @media (max-width: ${SIZES.thirdBreakPoint}){

        h2{
            font-size: 2rem;
        }

       

        .fond-hero{

            .image-fond{
                bottom:320px;
                left:50%;
                transform:translateX(-50%);
            }
        }
    }

    @media (max-width: ${SIZES.fourthBreakPoint}){

        h1{
            font-size: 2.8rem;
        }

        h1.page-main-title{
            font-size: 2.8rem;

            margin-top:48px;
        }

        h1.single-title{
            font-size: 2rem;
        }

        .fond-hero{
            height:800px;
            .image-fond{
                bottom:40vh;
                left:50%;
                transform:translateX(-50%);
            }
        }
        
    }
`

const Wrapper = styled.div`
   
    flex-grow: 1;
    margin: 48px 48px 96px;
    
    &.portfolioWrapper
    {
        margin: 0px 48px 96px;
        
    }

    main {
        max-width:${SIZES.container} ;
        margin:auto;
        position:relative;
        
    }

    .rso-bottom{

        max-width: 182px;
        margin: 72px auto;
        ul{
            
            list-style-type: none;
            display: flex;
            
            justify-content: space-between;
            
            
        }
    }

    @media (max-width: ${SIZES.thirdBreakPoint}){
        margin:48px 16px 48px;

        &.portfolioWrapper
        {
            margin: 0px 16px 96px;
            
        }
    }

    

    
`



const Layout = ({ children, theme="general", page="home" }) => {

    const portfolioTheme = {
        backgroundColor:""
    }
    const blogTheme = {
        backgroundColor:""
    }

    const themeBG = theme === "portfolio" ? portfolioTheme : theme === "blog" ? blogTheme : ''
    const portfolio = page === "portfolio" ? "portfolioWrapper" : ""


    /*const hero = theme==="index" ? <Image className="image-fond" fluid={data.casquette.childImageSharp.fluid} imgStyle= { {objectFit:'cover'} } /> : ""*/
    const hero = theme==="index" ? <div className="fond-hero"><StaticImage className="image-fond" src="../images/transparent-cap-shadow.png" objectFit="contain" placeholder="none"/></div> : ""
    
    return (
        <MainContainer themeBG={themeBG}>
         
             {hero}
            <Header theme={theme} page={page}/>
         
            <Wrapper className={portfolio}>
         
                <main>
                    {children}
                </main>
                <BlockRSO />

            </Wrapper>
      
            <Footer/>

            
        </MainContainer>
    );
};

export default Layout;