/*secundary: '#EEE', thirdary: '#f07e42'*/

const COLORS = {
    textColor:'#021124',
    primary: '#021124',
    secundary: '#fefefe',
    thirdary: '#FF6D2C',
    fourthary:'#f8f9fd',
    primaryHover:'#fc824c',
    secundaryHover:'#f0f2fa',
    thirdaryHover:'#fc8d5b'
  }
  
  export default COLORS;